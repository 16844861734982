import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'

const ListOrganisasi = () => {
    const dataTeam = [
        {
            id: 1,
            jabatan: "Direktur",
            anggota: {
                nama: "Kartini, S.Pd",
                nama2: "",
                nama3: "",
                nama4: "",
            },
        },
        {
            id: 2,
            jabatan: "General Affair",
            anggota: {
                nama: "Muhammad Syafri, S.Pd., M.Si., Ph.D",
                nama2: "",
                nama3: "",
                nama4: "",
            },
        },
        {
            id: 3,
            jabatan: "Keuangan",
            anggota: {
                nama: "Hj. Hamsiah, S.Pd",
                nama2: "",
                nama3: "",
                nama4: "",
            },
        },
        {
            id: 4,
            jabatan: "Tenaga Ahli Psikologi Industri dan Organisasi",
            anggota: {
                nama: "Hillman Wirawan, S.Psi., MM., MA(IO Psych).,Ph.D (cand.)",
                nama2: "",
                nama3: "",
                nama4: "",
            },
        },
        {
            id: 5,
            jabatan: "Tester",
            anggota: {
                nama: "Iqbal, S.Pd",
                nama2: "",
                nama3: "",
                nama4: "",
            },
        },
        {
            id: 6,
            jabatan: "Tenaga Ahli Psikologi",
            anggota: {
                nama: "Hj. Nur Andini Sudirman, M.Psi., Psikolog",
                nama2: "Asma, S.Psi., M.Pd",
                nama3: "Novina Sabila Zahra, S.Psi.",
                nama4: "",
            },
        },
        {
            id: 7,
            jabatan: "Tenaga Ahli IT",
            anggota: {
                nama: "Muhammad Hidayat Mubin, S.Pi",
                nama2: "Muh. Jundullah SP., ST",
                nama3: "",
                nama4: "",
            },
        },
        {
            id: 8,
            jabatan: "Tenaga Ahli Pendidikan",
            anggota: {
                nama: "Muhammad Arjul, SIP., M.Si",
                nama2: "Sudarmin,S.Pd.,M.Pd",
                nama3: "",
                nama4: "",
            },
        },
        {
            id: 9,
            jabatan: "Tenaga Pemasaran",
            anggota: {
                nama: "Rizal Rahman",
                nama2: "Muslimin Yunus",
                nama3: "",
                nama4: "",
            },
        },
    ]
    return (
        <>
            <div className='lg:container p-5 mx-auto'>
                <div className='grid gap-5 lg:grid-cols-2 py-10'>
                    {dataTeam.map((e) => {
                        return (
                            <div className='flex items-center border border-slate-300 rounded-md p-4 w-full h-fit shadow-md bg-white transition duration-300 hover:shadow-lg'>
                                <div>
                                    <p className='font-bold text-xl text-green-600'>{e.jabatan}</p>
                                    <ul>
                                        <li>{e.anggota.nama}</li>
                                        <li>{e.anggota.nama2}</li>
                                        <li>{e.anggota.nama3}</li>
                                        <li>{e.anggota.nama4}</li>
                                    </ul>
                                </div>
                                <hr className='border border-b-purple-400' />
                            </div>
                        )
                    })}
                </div>
            </div>
        </>
    )
}

const StrukturOrganisasi = () => {
    return (
        <div>
            <Navbar />

            <div className='bg-slate-100 h-fit w-full text-slate-800'>
                <div className='bg-gradient-to-r from-blue-600 to bg-purple-800 w-full h-40 flex justify-center items-center'>
                    <h1 className='text-6xl font-bold text-white text-center'>Struktur Organisasi</h1>
                </div>
                <ListOrganisasi />
            </div>

            <Footer />
        </div>
    )
}

export default StrukturOrganisasi