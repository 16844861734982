import React from 'react'
import { BiLeftArrowAlt } from 'react-icons/bi'
import { Link } from 'react-router-dom'

const Login = () => {
    return (
        <div className='flex text-slate-700'>
            {/* Left */}
            <div className="hero min-h-screen bg-slate-200">
                <form className="card-body w-2/3">
                    <div>
                        <h2 className='text-4xl'>Selamat datang</h2>
                    </div>
                    <hr className='border border-blue-500' />
                    <div className="form-control">
                        <label className="label">
                            <span className="text-slate-700 font-semibold">Email</span>
                        </label>
                        <input type="email" placeholder="Masukkan email" className="bg-white input input-bordered" required />
                    </div>
                    <div className="form-control">
                        <label className="label">
                            <span className="text-slate-700 font-semibold">Password</span>
                        </label>
                        <input type="password" placeholder="Masukkan password" className="bg-white input input-bordered" required />
                        <div className="mt-2">
                            <Link to="/" className="text-slate-700 hover:text-blue-500 underline">Forgot password?</Link>
                        </div>
                    </div>
                    <div className="form-control mt-6">
                        <button className="bg-blue-500 p-2 text-white font-semibold rounded-md duration-300 transition hover:bg-blue-400">Login</button>
                    </div>
                    <Link to='/' className='flex items-center duration-300 transition hover:text-blue-500'>
                        <BiLeftArrowAlt className='text-xl' />
                        <p>Kembali ke homepage</p>
                    </Link>
                </form>
            </div>

            {/* Right */}
            <div className="hero min-h-screen bg-yellow-300">
                <h2>Illustrasi gambar</h2>
            </div>
        </div>
    )
}

export default Login