import React from 'react'
import { Link } from 'react-router-dom'

const BannerDaftarProgram = () => {
    return (
        <div className='text-5xl text-center font-bold bg-gradient-to-r from-blue-600 to bg-purple-800 py-10'>
            <div className='lg:container mx-auto text-white'>
                <h2>Ayo daftarkan dirimu di program ini!</h2>

                <div className='flex justify-center'>
                    <Link to="/layanan/daftar-program" className='text-base mt-10 py-5 w-80 text-center font-semibold bg-white text-black rounded-full transition duration-300 hover:bg-blue-600 hover:text-white'>Daftar Sekarang</Link>
                </div>
            </div>
        </div>
    )
}

export default BannerDaftarProgram