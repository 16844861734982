import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import { Link } from 'react-router-dom'
import { BiLeftArrowAlt } from 'react-icons/bi'

const NotFound = () => {
    return (
        <div>
            <Navbar />
            <div className='bg-white text-slate-700'>
                <div className='container mx-auto py-32 grid gap-3'>
                    <h2 className='text-5xl text-center font-bold'>Halaman tidak ditemukan!</h2>
                    <Link to='/' className='flex items-center justify-center duration-300 transition hover:text-blue-500'>
                        <BiLeftArrowAlt className='text-xl' />
                        <p>Kembali ke homepage</p>
                    </Link>
                </div>
            </div>

            <Footer />
        </div>
    )
}

export default NotFound