import React, { useState } from 'react'
import Navbar from '../../components/Navbar'
import Footer from '../../components/Footer'
import { BiCheck, BiPhoneCall, BiTime } from 'react-icons/bi'
import { Radio } from 'antd'
import { useNavigate } from 'react-router-dom'

const DaftarMentor = () => {
    const [paket, setPaket] = useState();
    const navigate = useNavigate();

    const namaPaketMentoring = [
        {
            id: "1",
            nm_paket: 'Konsultasi Program Profesi TNI dan POLRI',
            kategori: 'Recomended',
            waktu: "75 Menit",
            voice: 'Via Voice Call',
            price: "Rp. 145.000",
            desciprtion_price: {
                text_satu: "Konsultasi melalui chat / voice call",
                text_dua: "Secara 1-on-1 dengan Mentor",
                text_tiga: "Konsultasi online melalui Google Meet",
            },
            description: {
                desc_satu: "Catatan konsultasi",
                desc_dua: "Worksheet",
                desc_tiga: "Tes minat karir",
                desc_empat: "Lembar hasil psikotest",
                desc_lima: "Test kesehatan mental",
            }
        },
        {
            id: "2",
            nm_paket: 'Perencanaan Karir',
            kategori: 'Basic',
            waktu: "75 Menit",
            voice: 'Via Voice Call',
            price: "Rp. 150.000",
            desciprtion_price: {
                text_satu: "Konsultasi melalui chat / voice call",
                text_dua: "Secara 1-on-1 dengan Mentor",
                text_tiga: "Konsultasi online melalui Google Meet",
            },
            description: {
                desc_satu: "Catatan konsultasi",
                desc_dua: "Worksheet",
                desc_tiga: "Tes minat karir",
                desc_empat: "Lembar hasil psikotest",
                desc_lima: "Test kesehatan mental",
                desc_enam: "Test kepribadian",
                desc_tujuh: "Test minat karir",
                desc_delapan: "Test distress psikologis",
            }
        },
    ]

    const onChangePaket = (e) => {
        console.log(`radio checked:${e.target.value}`);
        setPaket(e.target.value);
    }

    const handleButtonLanjut = () => {
        navigate(`/layanan/form-daftar`, { state: { data: { paket } } });
    }

    return (
        <div>
            <Navbar />

            <div className='bg-white text-black'>
                <div className='lg:container mx-auto p-5'>
                    {/* Mentoring */}
                    <div>
                        <div>
                            <h2 className='text-2xl font-bold'>Lihat daftar paket konseling</h2>
                        </div>
                        <div className='my-2'>
                            <p>Lihat daftar paket</p>
                            <p className='text-sm'>Lihat sesuai kebutuhanmu</p>
                        </div>
                        <div>
                            <Radio.Group onChange={onChangePaket} buttonStyle='outline' defaultValue="A" className='grid grid-cols-2 gap-5'>
                                {namaPaketMentoring.map((e) => {
                                    return (
                                        <Radio.Button key={e.id} value={e.nm_paket} className='h-fit grid'>
                                            <div className='p-5'>
                                                <div className='flex flex-col gap-5'>
                                                    <div className='w-full'>
                                                        <div className='flex items-center justify-between text-xl font-semibold mb-5'>
                                                            <p>{e.nm_paket}</p>
                                                            <p className='ml-5 bg-red-500 py-1 px-2 text-center text-white rounded-full'>{e.kategori}</p>
                                                        </div>
                                                        <div className='flex text-cyan-700'>
                                                            <div className='flex items-center'>
                                                                <BiTime />
                                                                <p className='ml-1'>{e.waktu}</p>
                                                            </div>
                                                            <div className='flex items-center ml-5'>
                                                                <BiPhoneCall />
                                                                <p className='ml-1'>{e.voice}</p>
                                                            </div>
                                                        </div>
                                                        <hr className='border my-2 border-slate-300' />
                                                        <div className='text-sm'>
                                                            <p>{e.desciprtion_price.text_satu}</p>
                                                            <p>{e.desciprtion_price.text_dua}</p>
                                                            <p>{e.desciprtion_price.text_tiga}</p>
                                                        </div>
                                                        <hr className='border my-2 border-slate-300' />
                                                        <div className='text-sm'>
                                                            <div className='flex items-center'>
                                                                <BiCheck className='text-xl text-green-400 w-5 h-5' />
                                                                <p className='ml-2'>{e.description.desc_satu}</p>
                                                            </div>
                                                            <div className='flex items-center'>
                                                                <BiCheck className='text-xl text-green-400 w-5 h-5' />
                                                                <p className='ml-2'>{e.description.desc_dua}</p>
                                                            </div>
                                                            <div className='flex items-center'>
                                                                <BiCheck className='text-xl text-green-400 w-5 h-5' />
                                                                <p className='ml-2'>{e.description.desc_tiga}</p>
                                                            </div>
                                                            <div className='flex items-center'>
                                                                <BiCheck className='text-xl text-green-400 w-5 h-5' />
                                                                <p className='ml-2'>{e.description.desc_empat}</p>
                                                            </div>
                                                            <div className='flex items-center'>
                                                                <BiCheck className='text-xl text-green-400 w-5 h-5' />
                                                                <p className='ml-2'>{e.description.desc_lima}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='w-full flex justify-center items-center'>
                                                        <div className='text-center'>
                                                            <p className='text-2xl font-semibold'>{e.price}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Radio.Button>
                                    )
                                })}
                            </Radio.Group>

                            <div className='flex justify-end my-5'>
                                <button onClick={handleButtonLanjut} className='bg-blue-500 py-2 px-4 font-semibold text-md text-white rounded-md transition duration-300 hover:bg-blue-700'>Lanjut</button>
                                {/* <a href="/layanan/daftar-mentor/form/" className='bg-blue-500 p-2 font-semibold text-md text-white rounded-md'>Lanjut</a> */}
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <Footer />
        </div>
    )
}

export default DaftarMentor