import React, { useState } from 'react'
import Navbar from '../../components/Navbar'
import Footer from '../../components/Footer'
import { useLocation } from 'react-router-dom'
import { PiShieldWarning } from 'react-icons/pi'

const FormDaftar = () => {
    const location = useLocation();
    const [formData, setFormData] = useState({
        nama_lengkap: "",
        paket: "",
        nomor: "",
        jenis_kelamin: "",
    })

    const handleChange = (e) => {
        const { name, value } = e.target
        setFormData({ ...formData, [name]: value })
    }

    const handleLanjut = (e) => {
        console.log(e);
        e.preventDefault();

        ///kirim ke whatsapp
        const url = "https://wa.me/6282310949180?text=" +
            "Halo, saya tertarik dengan membeli paket " + location.state.data.paket + "%0a %0a" +
            "Nama : " + formData.nama_lengkap + "%0a" +
            "Nomor Whatsapp : " + formData.nomor + "%0a" +
            "Provinsi : " + formData.provinsi + "%0a" +
            "Jenis Kelamin : " + formData.jenis_kelamin + "%0a %0a" +
            "Mohon untuk segera di proses ya."

        ///buka di page lain
        window.open(url, "_blank").focus();
    }

    const deskripsiForm = [
        {
            text: 'Pastikan isi dengan benar'
        },
        {
            text: 'Harap isi data dirimu dengan benar'
        },
        {
            text: 'Registrasi Form Amanah Bestari diperlukan untuk memilih jadwal mentoring.'
        }
    ]

    const nama_provinsi = [
        "Aceh",
        "Sumatera Utara",
        "Sumatera Barat",
        "Riau",
        "Kepulauan Riau",
        "Jambi",
        "Bengkulu",
        "Sumatera Selatan",
        "Kepulauan Bangka Belitung",
        "Lampung",
        "DKI Jakarta",
        "Banten",
        "Jawa Barat",
        "Jawa Tengah",
        "DI Yogyakarta",
        "Jawa Timur",
        "Bali",
        "Nusa Tenggara Barat",
        "Nusa Tenggara Timur",
        "Kalimantan Barat",
        "Kalimantan Tengah",
        "Kalimantan Selatan",
        "Kalimantan Timur",
        "Kalimantan Utara",
        "Sulawesi Utara",
        "Gorontalo",
        "Sulawesi Tengah",
        "Sulawesi Barat",
        "Sulawesi Selatan",
        "Sulawesi Tenggara",
        "Maluku",
        "Maluku Utara",
        "Papua Barat",
        "Papua"
    ]

    return (
        <div>
            <Navbar />

            <div className='bg-white text-black'>
                <div className='lg:container mx-auto p-5'>
                    <div>
                        <h2 className='text-2xl font-bold text-center'>Pendaftaran Online</h2>
                    </div>
                    <form onSubmit={handleLanjut}>
                        <div className='grid lg:grid-cols-2 gap-5 my-10'>
                            <div>
                                <p className='mb-1'>Nama Lengkap</p>
                                <input name='nama_lengkap' onChange={handleChange} type="text" required className='bg-white w-full p-2 outline-none border border-slate-500 rounded-md' placeholder='Masukkan nama' />
                            </div>
                            <div>
                                <p className='mb-1'>Pilih Menu Paket</p>
                                <input disabled name='paket' onChange={handleChange} type="text" required value={location.state.data.paket} className='bg-white w-full p-2 outline-none border border-slate-500 rounded-md' placeholder='Pilih Paket' />
                            </div>
                            <div>
                                <p>Nomor Whatsapp</p>
                                <input name='nomor' onChange={handleChange} type="text" required className='bg-white w-full p-2 outline-none border border-slate-500 rounded-md' placeholder='Masukkan nomor whatsapp' />
                            </div>
                            <div>
                                <p>Provinsi</p>
                                <select name="provinsi" onChange={handleChange} required className='bg-white w-full p-2 outline-none border border-slate-500 rounded-md'>
                                    <option disabled selected>Pilih Provinsi</option>
                                    {nama_provinsi.map((array, i) => (
                                        <option key={i}>{array}</option>
                                    ))}
                                </select>
                            </div>
                            <div>
                                <p>Jenis Kelamin</p>
                                <select name='jenis_kelamin' onChange={handleChange} required className='bg-white w-full p-2 outline-none border border-slate-500 rounded-md'>
                                    <option disabled selected>Pilih gender</option>
                                    <option>Laki laki</option>
                                    <option>Perempuan</option>
                                </select>
                            </div>
                        </div>

                        {/* direct ke wa */}
                        <div className='flex justify-end'>
                            <button type="submit" className='bg-blue-500 py-2 px-4 font-semibold text-md text-white rounded-md transition duration-300 hover:bg-blue-700'>Lanjut</button>
                        </div>
                    </form>

                    <div className='text-sm grid gap-1'>
                        {deskripsiForm.map((e) => {
                            return (
                                <>
                                    <div className='flex items-center'>
                                        <PiShieldWarning className='text-xl' />
                                        <p className='ml-2 w-full'>{e.text}</p>
                                    </div>
                                </>
                            )
                        })}
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    )
}

export default FormDaftar