import React, { useState } from 'react'
import LogoAmanah from '../img/logo-navbar.png'

import { BiMenu, BiRightArrowAlt } from "react-icons/bi";
import { Link } from 'react-router-dom';
import { IoIosArrowDown, IoIosArrowForward } from 'react-icons/io';

const NavbarDesktop = () => {
    const [isHovered2, setIsHovered2] = useState(false);
    const [isHovered3, setIsHovered3] = useState(false);
    const [isHovered4, setIsHovered4] = useState(false);
    const [isHovered7, setIsHovered7] = useState(false);
    return (
        <>
            <div className='hidden lg:block'>
                <div className='flex items-center'>
                    {/* desktop */}
                    <div className="navbar-center hidden lg:flex font-semibold">
                        {/* Menu layanan */}

                        {/* Home */}
                        <Link to='/' className="p-3 bg-white flex items-center duration-300 transition hover:bg-slate-200">
                            <p className='mr-2'>Home</p>
                        </Link>

                        {/* Layanan */}
                        <div className="dropdown dropdown-hover">
                            <div tabIndex={0} role="button" className="p-3 bg-white flex items-center duration-300 transition hover:bg-slate-200">
                                <p className='mr-2'>Layanan</p>
                                <IoIosArrowDown />
                            </div>
                            <ul tabIndex={0} className="dropdown-content rounded-xl z-[1] menu shadow bg-white w-60">
                                {/* 1 */}
                                <div tabIndex={0} role="button" className="p-2 rounded-xl flex items-center justify-between transition duration-300 hover:bg-slate-200">
                                    <Link to='/layanan-mitra' className='mr-1'>MITRA</Link>
                                </div>

                                {/* 2 */}
                                <div
                                    className="dropdown dropdown-right"
                                    onMouseEnter={() => setIsHovered2(true)}
                                    onMouseLeave={() => setIsHovered2(false)}
                                >
                                    <div tabIndex={0} role="button" className="p-2 flex rounded-xl items-center justify-between transition duration-300 hover:bg-slate-200">
                                        <p className='mr-1'>KONSELING KARIR</p>
                                        <IoIosArrowForward className='text-lg' />
                                    </div>
                                    <ul
                                        tabIndex={0}
                                        className={`dropdown-content rounded-xl menu z-[1] p-2 shadow bg-white w-60 ${isHovered2 ? 'block' : 'hidden'}`}
                                        onMouseEnter={() => setIsHovered2(true)}
                                        onMouseLeave={() => setIsHovered2(false)}
                                    >
                                        <li><Link to='/konseling-karir/konsultasi-program' className='transition duration-300 hover:bg-slate-200'>KONSULTASI PROGRAM PROFESI TNI DAN POLRI</Link></li>
                                        <li><Link to='/konseling-karir/perencanaan-karir' className='transition duration-300 hover:bg-slate-200'>PERENCANAAN KARIR</Link></li>
                                    </ul>
                                </div>

                                {/* 3 */}
                                <div
                                    className="dropdown dropdown-right"
                                    onMouseEnter={() => setIsHovered3(true)}
                                    onMouseLeave={() => setIsHovered3(false)}
                                >
                                    <div tabIndex={0} role="button" className="p-2 flex rounded-xl items-center justify-between transition duration-300 hover:bg-slate-200">
                                        <p className='mr-1'>PROGRAM KESAMAPTAAN</p>
                                        <IoIosArrowForward className='text-lg' />
                                    </div>
                                    <ul
                                        tabIndex={0}
                                        className={`dropdown-content rounded-xl menu z-[1] p-2 shadow bg-white w-60 ${isHovered3 ? 'block' : 'hidden'}`}
                                        onMouseEnter={() => setIsHovered3(true)}
                                        onMouseLeave={() => setIsHovered3(false)}
                                    >
                                        <li><Link to='/program-kesamaptaan/a' className='transition duration-300 hover:bg-slate-200'>SAMAPTA A</Link></li>
                                        <li><Link to='/program-kesamaptaan/b' className='transition duration-300 hover:bg-slate-200'>SAMAPTA B</Link></li>
                                        <li><Link to='/program-kesamaptaan/c' className='transition duration-300 hover:bg-slate-200'>SAMAPTA C</Link></li>
                                        <li><Link to='/program-kesamaptaan/tentang-kesamaptaan' className='transition duration-300 hover:bg-slate-200'>Tentang Kesamaptaan</Link></li>
                                    </ul>
                                </div>

                                {/* 4 */}
                                <div
                                    className="dropdown dropdown-right"
                                    onMouseEnter={() => setIsHovered4(true)}
                                    onMouseLeave={() => setIsHovered4(false)}
                                >
                                    <div tabIndex={0} role="button" className="p-2 flex rounded-xl items-center justify-between transition duration-300 hover:bg-slate-200">
                                        <p className='mr-1'>TEAM BUILDING </p>
                                        <IoIosArrowForward className='text-lg' />
                                    </div>
                                    <ul
                                        tabIndex={0}
                                        className={`dropdown-content rounded-xl menu z-[1] p-2 shadow bg-white w-60 ${isHovered4 ? 'block' : 'hidden'}`}
                                        onMouseEnter={() => setIsHovered4(true)}
                                        onMouseLeave={() => setIsHovered4(false)}
                                    >
                                        <li><Link to='/team-building/latihan-kepemimpinan' className='transition duration-300 hover:bg-slate-200'>LATIHAN KEPEMIMPINAN</Link></li>
                                        <li><Link to='/team-building/program-outbond' className='transition duration-300 hover:bg-slate-200'>PROGRAM OUTBOND </Link></li>
                                    </ul>
                                </div>
                            </ul>
                        </div>

                        {/* Menu asesmen psikologi */}
                        <Link to='/asesmen-psikologi' className="p-3 bg-white flex items-center duration-300 transition hover:bg-slate-200">
                            <p className='mr-2'>Asesmen Psikologi</p>
                        </Link>

                        {/* Menu kerja sama */}
                        <div className='dropdown dropdown-hover'>
                            <div tabIndex={0} role="button" className="p-3 bg-white flex items-center duration-300 transition hover:bg-slate-200">
                                <p className='mr-2'>Kerja Sama</p>
                                <IoIosArrowDown />
                            </div>
                            <ul tabIndex={0} className="dropdown-content rounded-xl z-[1] menu shadow bg-white w-60">
                                <div tabIndex={0} role="button" className="p-2 flex rounded-xl items-center justify-between transition duration-300 hover:bg-slate-200">
                                    <Link to='/track-record' className='mr-1'>TRACK RECORD LAYANAN AMANAH</Link>
                                </div>
                                <div
                                    className="dropdown dropdown-right"
                                    onMouseEnter={() => setIsHovered7(true)}
                                    onMouseLeave={() => setIsHovered7(false)}
                                >
                                    <div tabIndex={0} role="button" className="p-2 flex rounded-xl items-center justify-between transition duration-300 hover:bg-slate-200">
                                        <p className='mr-1'>LAYANAN BIMBINGAN LANJUTAN</p>
                                        <IoIosArrowForward className='text-xl' />
                                    </div>
                                    <ul
                                        tabIndex={0}
                                        className={`dropdown-content rounded-xl menu z-[1] p-2 shadow bg-white w-60 ${isHovered7 ? 'block' : 'hidden'}`}
                                        onMouseEnter={() => setIsHovered7(true)}
                                        onMouseLeave={() => setIsHovered7(false)}
                                    >
                                        <li><a href='https://www.instagram.com/bibitind/' className='transition duration-300 hover:bg-slate-200'>BIBIT</a></li>
                                        <li><a href='https://screenesia.com/' className='transition duration-300 hover:bg-slate-200'>SCREENESIA</a></li>
                                        <li><a href='https://grahacendekia.com/' className='transition duration-300 hover:bg-slate-200'>GANESHA CENDEKIA INDONESIA</a></li>
                                    </ul>
                                </div>
                                <div tabIndex={0} role="button" className="p-2 flex rounded-xl items-center justify-between transition duration-300 hover:bg-slate-200">
                                    <Link to='/layanan-training' className='mr-1'>LAYANAN TRAINING</Link>
                                </div>
                            </ul>
                        </div>

                        {/* Tentang kami */}
                        <div className='dropdown dropdown-hover'>
                            <div tabIndex={0} role="button" className="p-3 bg-white flex items-center duration-300 transition hover:bg-slate-200">
                                <p className='mr-2'>Tentang Kami</p>
                                <IoIosArrowDown />
                            </div>
                            <ul tabIndex={0} className="dropdown-content rounded-xl z-[1] menu shadow bg-white w-60">
                                <div tabIndex={0} role="button" className="p-2 flex rounded-xl items-center justify-between transition duration-300 hover:bg-slate-200">
                                    <Link to='/about-us' className='mr-1'>CV AMANAH BESTARI CORPORATION</Link>
                                </div>
                                <div tabIndex={0} role="button" className="p-2 flex rounded-xl items-center justify-between transition duration-300 hover:bg-slate-200">
                                    <Link to='/struktur-organisasi' className='mr-1'>STRUKTUR ORGANISASI</Link>
                                </div>
                            </ul>
                        </div>
                    </div>

                    <div className="divider divider-horizontal divider-primary"></div>

                    {/* Login */}
                    <div className="navbar-end">
                        {/* <Link to='/login' className="bg-blue-600 px-4 py-2 rounded text-white duration-300 transition hover:bg-blue-500">Masuk</Link> */}
                        <a href='https://linktr.ee/layananamanah' className="bg-blue-600 px-4 py-2 rounded text-white duration-300 transition hover:bg-blue-500">Masuk</a>
                    </div>
                </div>
            </div>
        </>
    )
}

const NavbarMobile = () => {
    return (
        <>
            <div className='lg:hidden block'>
                <div className="drawer drawer-end">
                    <input id="my-drawer-4" type="checkbox" className="drawer-toggle" />
                    <div className="drawer-content">
                        {/* Page content here */}
                        <label htmlFor="my-drawer-4" className="drawer-button">
                            <BiMenu className='text-5xl text-blue-700' />
                        </label>
                    </div>
                    <div className="drawer-side">
                        <label htmlFor="my-drawer-4" aria-label="close sidebar" className="drawer-overlay"></label>
                        <ul className="menu p-4 w-80 min-h-full bg-white text-black">
                            {/* Sidebar content here */}
                            <h3 className='font-semibold text-center'>Menu</h3>

                            {/* Home */}
                            <li>
                                <Link to='/'>Home</Link>
                            </li>

                            {/* Menu layanan */}
                            <ul className="rounded-box">
                                <li>
                                    <details>
                                        <summary>Layanan</summary>
                                        <ul>
                                            <li>
                                                <summary>
                                                    <Link to='/layanan-mitra' className='flex items-center'>
                                                        <BiRightArrowAlt />
                                                        <p className='ml-1'>Mitra</p>
                                                    </Link>
                                                </summary>
                                            </li>
                                            <li>
                                                <details>
                                                    <summary>Konseling karir</summary>
                                                    <ul>
                                                        <li><Link to='/konseling-karir/konsultasi-program'>
                                                            <BiRightArrowAlt />
                                                            <p>Konsultasi Program Profesi TNI dan POLRI</p>
                                                        </Link></li>
                                                        <li><Link to='/konseling-karir/perencanaan-karir'>
                                                            <BiRightArrowAlt />
                                                            <p>Perencanaan Karir</p>
                                                        </Link></li>
                                                    </ul>
                                                </details>
                                                <details>
                                                    <summary>Program kesamaptaan</summary>
                                                    <ul>
                                                        <li>
                                                            <Link to='/program-kesamaptaan/a'>
                                                                <BiRightArrowAlt />
                                                                <p>SAMAPTA A</p>
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to='/program-kesamaptaan/b'>
                                                                <BiRightArrowAlt />
                                                                <p>SAMAPTA B</p>
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to='/program-kesamaptaan/c'>
                                                                <BiRightArrowAlt />
                                                                <p>SAMAPTA C</p>
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to='/program-kesamaptaan/tentang-kesamaptaan' className='transition duration-300 hover:bg-slate-200'>
                                                                <BiRightArrowAlt />
                                                                <p>Tentang Kesamaptaan</p>
                                                            </Link>
                                                        </li>
                                                    </ul>
                                                </details>
                                                <details>
                                                    <summary>Team Building</summary>
                                                    <ul>
                                                        <li><Link to='/team-building/latihan-kepemimpinan'>
                                                            <BiRightArrowAlt />
                                                            <p>Latihan Kepemimpinan</p>
                                                        </Link></li>
                                                        <li><Link to='/team-building/program-outbond'>
                                                            <BiRightArrowAlt />
                                                            <p>Program Outbond</p>
                                                        </Link></li>
                                                    </ul>
                                                </details>
                                            </li>
                                        </ul>
                                    </details>
                                </li>

                                {/* Menu asesmen psikologi */}
                                <li>
                                    <Link to='/asesmen-psikologi'>Asesmen Psikologi</Link>
                                </li>

                                {/* Menu kerja sama */}
                                <li>
                                    <details>
                                        <summary>Kerja Sama</summary>
                                        <ul>
                                            <li>
                                                <Link to='/track-record'>
                                                    <BiRightArrowAlt />
                                                    <p>Track Record Layanan Amanah</p>
                                                </Link>
                                            </li>
                                            <li>
                                                <details>
                                                    <summary>Layanan Bimbingan Lanjutan</summary>
                                                    <ul>
                                                        <li>
                                                            <a href='https://www.instagram.com/bibitind/'>
                                                                <BiRightArrowAlt />
                                                                <p>Bibit</p>
                                                            </a>
                                                            <a href='https://screenesia.com/'>
                                                                <BiRightArrowAlt />
                                                                <p>Screenasia</p>
                                                            </a>
                                                            <a href='https://grahacendekia.com/'>
                                                                <BiRightArrowAlt />
                                                                <p>Ganesha Cendekia Indonesia</p>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </details>
                                            </li>
                                            <li>
                                                <Link to='/layanan-training'>
                                                    <BiRightArrowAlt />
                                                    <p>Layanan training</p>
                                                </Link>
                                            </li>
                                        </ul>
                                    </details>
                                </li>
                            </ul>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}

const Navbar = () => {
    return (
        <div className='bg-white text-black sticky top-0 shadow z-50 w-full'>
            <div className="flex justify-between items-center mx-auto p-2 lg:container">
                
                <Link to='/' className="navbar-start flex items-center w-full">
                    <img src={LogoAmanah} alt="" className='w-fit h-10' />
                </Link>

                <NavbarDesktop />
                <NavbarMobile />

            </div >
        </div >
    )
}

export default Navbar