import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import BannerDaftarProgram from '../components/Services/BannerDaftarProgram'
import GambarSamaptaRenang from '../img/samapta-renang.jpg'

const SamaptaA = () => {
  return (
    <div>
      <Navbar />

      <div className='bg-slate-100 w-full text-slate-800'>
        <div className='bg-gradient-to-r from-blue-600 to-purple-800 w-full h-20 flex justify-center items-center'>
          <h1 className='lg:text-4xl text-2xl font-bold text-white'>Samapta A: Pelatihan Renang</h1>
        </div>
        <div className='lg:container mx-auto p-5'>
          <img src={GambarSamaptaRenang} alt='' className='h-fit lg:h-[25rem] w-full lg:w-8/12 lg:mx-auto border-2 rounded-box' />
          <div className='text-justify my-5 grid gap-5'>
            <h2 className='font-bold text-xl'>Lorem ipsum dolor sit amet.</h2>
            <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Culpa atque reprehenderit necessitatibus quas tempore ad placeat eaque quae facilis sapiente? Lorem ipsum dolor sit amet consectetur adipisicing elit. Vitae repellat delectus repellendus veritatis nesciunt magnam sapiente deleniti velit. Deserunt possimus commodi minus similique repudiandae consequuntur saepe sunt itaque aspernatur recusandae dolor repellat omnis atque id aperiam quis, enim dolores sint fuga porro sit, nihil quod? Similique, reiciendis eius eaque, iste tempore dolorem pariatur modi odio quaerat autem corporis! Natus molestiae debitis illum laboriosam non, repudiandae similique error obcaecati quisquam, aperiam dolorum ducimus. Itaque ut, asperiores ex numquam iure enim accusamus, vel quis, nam laborum commodi? Aperiam voluptate iste alias nam aut odit reprehenderit ab soluta ipsum, explicabo a, accusamus in animi quidem cum pariatur, dolor amet consequatur culpa et. Beatae quod facere quaerat fuga. Delectus nemo maxime exercitationem dolore tempore cupiditate rem id ipsum alias recusandae. Vel atque quia vitae porro qui in facere velit, esse autem, eligendi adipisci fugit animi ipsam? Magnam ex neque id quas tempora itaque. Neque alias maxime iure ipsam, perspiciatis maiores eum amet beatae in blanditiis dolores totam reprehenderit, quae nostrum recusandae labore et cumque exercitationem inventore libero? Voluptatem, deserunt? Atque beatae eaque corrupti fuga labore mollitia officiis eos consectetur distinctio asperiores iusto excepturi non quidem deleniti sit laudantium porro possimus, facilis sequi, nemo reprehenderit.</p>
            <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Reiciendis officiis minima, ducimus corrupti dolores sapiente, est reprehenderit porro itaque temporibus, qui nesciunt earum cupiditate distinctio. Architecto soluta veniam asperiores. Nobis, iste aliquam ex dolorem perspiciatis, exercitationem, ut quidem cum unde fugit inventore consectetur reiciendis sit architecto accusantium! Cumque, sequi est.</p>
            <h2 className='font-bold text-xl'>Lorem ipsum dolor sit amet.</h2>
            <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Reiciendis officiis minima, ducimus corrupti dolores sapiente, est reprehenderit porro itaque temporibus, qui nesciunt earum cupiditate distinctio. Architecto soluta veniam asperiores. Nobis, iste aliquam ex dolorem perspiciatis, exercitationem, ut quidem cum unde fugit inventore consectetur reiciendis sit architecto accusantium! Cumque, sequi est.</p>
          </div>
        </div>
      </div>

      <BannerDaftarProgram />
      <Footer />
    </div>
  )
}

export default SamaptaA