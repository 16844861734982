import './App.css';
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom'

// index
import Homepage from './Layouts/Homepage';

// auth
import Login from './pages/Auth/Login';
import Register from './pages/Auth/Register';

// asesmen
import AsesmenPsikologi from './Layouts/AsesmenPsikologi';

// about us
import AboutUs from './Layouts/AboutUs';
import StrukturOrganisasi from './Layouts/StrukturOrganisasi';

// kerja sama
import TrackRecord from './Layouts/TrackRecord';
import LayananTraining from './Layouts/LayananTraining';

// layanan
import SamaptaA from './Layouts/SamaptaA';
import SamaptaB from './Layouts/SamaptaB';
import SamaptaC from './Layouts/SamaptaC';
import TentangKesamaptaan from './Layouts/TentangKesamaptaan';
import LayananMitra from './Layouts/LayananMitra';
import KonsultasiProgram from './Layouts/KonsultasiProgram';
import KonsultasiPerencanaanKarir from './Layouts/KonsultasiPerencanaanKarir';

// form
import FormDaftar from './pages/Forms/FormDaftar';
import DaftarMentor from './pages/Forms/DaftarMentor';
import DaftarAsesmen from './pages/Forms/DaftarAsesmen';
import DaftarProgram from './pages/Forms/DaftarProgram';

// team building
import LatihanKepemimpinan from './Layouts/LatihanKepemimpinan';
import ProgramOutbond from './Layouts/ProgramOutbond';

// 404
import NotFound from './Layouts/NotFound';

function App() {
  return (
    <Router>
      <Routes>

        {/* main menu */}
        <Route path='/' element={<Homepage />} />
        <Route path='/*' element={<NotFound />} />

        {/* authentikasi */}
        <Route path='/login' element={<Login />} />
        <Route path='/register-admin' element={<Register />} />

        {/* pemilihan paket - form */}
        <Route path='/layanan/daftar-mentor' element={<DaftarMentor />} />
        <Route path='/layanan/daftar-asesmen' element={<DaftarAsesmen />} />
        <Route path='/layanan/daftar-program' element={<DaftarProgram />} />
        <Route path='/layanan/form-daftar' element={<FormDaftar />} />

        {/* navbar - layanan */}
        <Route path='/layanan-mitra' element={<LayananMitra />} />
        <Route path='/konseling-karir/konsultasi-program' element={<KonsultasiProgram />} />
        <Route path='/konseling-karir/perencanaan-karir' element={<KonsultasiPerencanaanKarir />} />
        <Route path='/program-kesamaptaan/a' element={<SamaptaA />} />
        <Route path='/program-kesamaptaan/b' element={<SamaptaB />} />
        <Route path='/program-kesamaptaan/c' element={<SamaptaC />} />
        <Route path='/program-kesamaptaan/tentang-kesamaptaan' element={<TentangKesamaptaan />} />
        <Route path='/team-building/latihan-kepemimpinan' element={<LatihanKepemimpinan />} />
        <Route path='/team-building/program-outbond' element={<ProgramOutbond />} />

        {/* navbar - asesmen psikologi */}
        <Route path='/asesmen-psikologi' element={<AsesmenPsikologi />} />
        
        {/* navbar - kerja sama */}
        <Route path='/track-record' element={<TrackRecord />} />
        <Route path='/layanan-training' element={<LayananTraining />} />
        
        {/* navbar - tentang kami */}
        <Route path='/about-us' element={<AboutUs />}/>
        <Route path='/struktur-organisasi' element={<StrukturOrganisasi />}/>

      </Routes>
    </Router>
  );
}

export default App;