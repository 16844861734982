import React, { useEffect } from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import Marquee from "react-fast-marquee";
import Aos from 'aos';
import 'aos/dist/aos.css';

// import library
import { Link } from 'react-router-dom';
import { Carousel } from 'antd';

// import image
import BannerDaftarKonseling from '../components/Services/BannerDaftarKonseling';
import IconKomitmen from '../img/icon12.png'
import IconMedsos from '../img/medsos.png'
import IconMediaPlayer from '../img/media-player.png'
import IconKonseling from '../img/konseling.png'
import IconAsesmenPsikologi from '../img/asesmen-psikologi.png'
import Carousel1 from '../img/Slide-1.jpg'
import Carousel2 from '../img/Slide-2.jpg'
import Carousel3 from '../img/Slide-3.jpg'
import IconLayanan1 from '../img/icon10.png'
import IconLayanan2 from '../img/icon8.png'
import IconLayanan3 from '../img/icon9.png'
import Profile1 from '../img/profile-1.png'
import Profile2 from '../img/profile-2.jpg'
import Profile3 from '../img/profile-3.jpg'
import Profile4 from '../img/profile-4.jpg'
import SliderAnimasi1 from '../img/logo-apakri-news.png'
import SliderAnimasi2 from '../img/logo-rsi.png'
import SliderAnimasi3 from '../img/logo-simak-berita.png'
import SliderAnimasi4 from '../img/logo-bn.png'
import SliderAnimasi5 from '../img/logo-tribun-timur.png'
import SliderAnimasi6 from '../img/logo-pena-jurnal.png'
import SliderAnimasi7 from '../img/logo-simpul-rakyat.png'
import SliderAnimasi8 from '../img/logo-mata-kita.png'

const CarouselSlider = () => {
    return (
        <Carousel arrows autoplay autoplaySpeed={4000} infinite>
            {/* Slider 1 */}
            <div className='relative h-[45rem]'>
                <img src={Carousel1} alt="" className="h-full w-full" />
                <div className="absolute inset-0 py-10 bg-black bg-opacity-75 flex flex-col justify-center text-white">
                    <div className='lg:container lg:max-w-6xl mx-5'>
                        <h1 className='text-5xl lg:text-5xl font-semibold text-left'>
                            <p>Ingin Tahu Potensi Bakat Serta Mengukur Kemampuan IQ Anda</p>
                            <p className='text-yellow-400 mt-5'>Layanan Amanah Solusinya</p>
                        </h1>
                        <p className='my-5 text-base text-left'>Layanan amanah sebagai jasa pelayanan asesmen psikolog sejak 2010 melayani berbagai instansi pemerintahan dan swasta dalam penyelenggaraan asesmen psikologis serta bimbingan dan konseling</p>
                        <div className='my-5 flex'>
                            <Link to='/layanan/daftar-mentor' className='bg-gradient-to-r from-blue-600 to bg-purple-800 w-80 text-white flex justify-center rounded-full py-3 shadow font-semibold text-2xl duration-500 transition hover:bg-gradient-to-r hover:from-purple-800 to hover:bg-yellow-500 hover:text-white'>Daftar Sekarang</Link>
                        </div>
                    </div>
                </div>
            </div>
            {/* Slider 2 */}
            <div className='relative h-[45rem]'>
                <img src={Carousel2} alt="" className="h-full w-full" />
                <div className="absolute inset-0 py-10 bg-black bg-opacity-75 flex flex-col justify-center text-white">
                    <div className='lg:container lg:max-w-6xl mx-5'>
                        <h1 className='text-5xl lg:text-5xl font-semibold text-left'>
                            <p>Ingin Tahu Potensi Bakat Serta Mengukur Kemampuan IQ Anda</p>
                            <p className='text-yellow-400 mt-5'>Layanan Amanah Solusinya</p>
                        </h1>
                        <p className='my-5 text-base text-left'>Layanan amanah sebagai jasa pelayanan asesmen psikolog sejak 2010 melayani berbagai instansi pemerintahan dan swasta dalam penyelenggaraan asesmen psikologis serta bimbingan dan konseling</p>
                        <div className='my-5 flex'>
                            <Link to='/layanan/daftar-mentor' className='bg-gradient-to-r from-blue-600 to bg-purple-800 w-80 text-white flex justify-center rounded-full py-3 shadow font-semibold text-2xl duration-500 transition hover:bg-gradient-to-r hover:from-purple-800 to hover:bg-yellow-500 hover:text-white'>Daftar Sekarang</Link>
                        </div>
                    </div>
                </div>
            </div>
            {/* Slider 3 */}
            <div className='relative h-[45rem]'>
                <img src={Carousel3} alt="" className="h-full w-full" />
                <div className="absolute inset-0 py-10 bg-black bg-opacity-75 flex flex-col justify-center text-white">
                    <div className='lg:container lg:max-w-6xl mx-5'>
                        <h1 className='text-5xl lg:text-5xl font-semibold text-left'>
                            <p>Ingin Tahu Potensi Bakat Serta Mengukur Kemampuan IQ Anda</p>
                            <p className='text-yellow-400 mt-5'>Layanan Amanah Solusinya</p>
                        </h1>
                        <p className='my-5 text-base text-left'>Layanan amanah sebagai jasa pelayanan asesmen psikolog sejak 2010 melayani berbagai instansi pemerintahan dan swasta dalam penyelenggaraan asesmen psikologis serta bimbingan dan konseling</p>
                        <div className='my-5 flex'>
                            <Link to='/layanan/daftar-mentor' className='bg-gradient-to-r from-blue-600 to bg-purple-800 w-80 text-white flex justify-center rounded-full py-3 shadow font-semibold text-2xl duration-500 transition hover:bg-gradient-to-r hover:from-purple-800 to hover:bg-yellow-500 hover:text-white'>Daftar Sekarang</Link>
                        </div>
                    </div>
                </div>
            </div>
        </Carousel>
    )
}

const LayananKami = () => {
    return (
        <>
            <div className='bg-white h-fit w-full text-slate-800 py-20'>
                <div className='lg:container mx-auto'>
                    <div className='text-center mb-5'>
                        <h2 className='text-4xl font-bold'>Layanan Kami</h2>
                    </div>

                    <div className="w-full grid lg:grid-cols-3 gap-10 p-5">
                        {/* 1 */}
                        <div className="w-full max-w-lg p-3 flex flex-col gap-3 justify-between border-2 border-blue-300 rounded-box">
                            <img src={IconLayanan1} alt='' className='w-40 h-40 mx-auto' />
                            <div className='h-full'>
                                <h2 className='text-2xl font-bold text-center mb-2'>Konseling dengan Psikolog</h2>
                                <p className='mb-5'>Lorem ipsum dolor sit amet consectetur adupilicisng elit. Lorem ipsum dolor sit amet consectetur</p>
                            </div>
                            <Link to='/layanan/daftar-mentor' className='bg-gradient-to-r from-blue-600 to bg-purple-800 text-center text-white p-3 font-semibold rounded-full'>Klik untuk selanjutnya</Link>
                        </div>
                        {/* 2 */}
                        <div className="w-full max-w-lg p-3 flex flex-col gap-3 justify-between border-2 border-blue-300 rounded-box">
                            <img src={IconLayanan2} alt='' className='w-40 h-40 mx-auto' />
                            <div className='h-full'>
                                <h2 className='text-2xl font-bold text-center mb-2'>Asesmen Psikolog</h2>
                                <p className='mb-5'>Lorem ipsum dolor sit amet consectetur adupilicisng elit. Lorem ipsum dolor sit amet consectetur</p>
                            </div>
                            <Link to='/layanan/daftar-mentor' className='bg-gradient-to-r from-blue-600 to bg-purple-800 text-center text-white p-3 font-semibold rounded-full'>Klik untuk selanjutnya</Link>
                        </div>
                        {/* 3 */}
                        <div className="w-full max-w-lg p-3 flex flex-col gap-3 justify-between border-2 border-blue-300 rounded-box">
                            <img src={IconLayanan3} alt='' className='w-40 h-40 mx-auto' />
                            <div className='h-full'>
                                <h2 className='text-2xl font-bold text-center mb-2'>Program Kesamaptaan</h2>
                                <p className='mb-5'>Lorem ipsum dolor sit amet consectetur adupilicisng elit. Lorem ipsum dolor sit amet consectetur</p>
                            </div>
                            <Link to='/layanan/daftar-mentor' className='bg-gradient-to-r from-blue-600 to bg-purple-800 text-center text-white p-3 font-semibold rounded-full'>Klik untuk selanjutnya</Link>
                        </div>
                    </div>
                </div>

                <div className='lg:container mx-auto p-5 flex gap-5'>
                    <div className='grid gap-5'>
                        <div className='text-justify' data-aos='fade-up' data-aos-duration='1000'>
                            <h2 className='font-semibold text-lg'>Platform Kesehatan Mental dan Pengembangan Diri</h2>
                            <p className='text-sm'>Layanan Amanah adalah startup pendidikan yang mengajarkan tentang pengetahuan dan kemampuan penting dalam hidup yang belum diajarkan di sekolah dan masyarakat luas. Sebagai platform yang bergerak di bidang kesehatan mental, kami menawarkan berbagai layanan seputar kesehatan mental dan pengembangan diri. Kami selalu berusaha meningkatkan layanan dan menyediakan lebih banyak variasi di dalamnya, yang dapat diakses dari mana saja dan kapan saja. Sehingga mampu memberikan kemudahan dan kenyamanan bagi masyarakat luas untuk mendapatkan layanan seputar kesehatan mental dan pengembangan diri.</p>
                        </div>
                        <div className='text-justify' data-aos='fade-up' data-aos-duration='1100'>
                            <h2 className='font-semibold text-lg'>Layanan Amanah Terbesar di Indonesia Timur</h2>
                            <p className='text-sm'>Layanan Amanah bermula dari founders Satu Persen yang memiliki keresahan terkait permasalahan kesehatan mental dan pengembangan diri di Indonesia. Tujuan besar Satu Persen adalah ingin semua orang berdaya dengan memiliki identity-aware, active problem solver dan growth mindset. Untuk mencapai tujuan itu, kami memiliki kurikulumnya sendiri yang diturunkan menjadi produk & layanan utama seperti Mentoring, Konseling, Kelas Online, Webinar, Tes Online Gratis, dan Basic Mental Health Training.</p>
                        </div>
                    </div>
                    <div className='grid gap-5'>
                        <div className='mb-5 text-justify' data-aos='fade-up' data-aos-duration='1300'>
                            <h2 className='font-semibold text-lg'>Layanan Amanah seputar Kesehatan Mental dan Pengembangan Diri</h2>
                            <p className='text-sm'>Layanan Amanah mengembangkan suatu platform terpusat dimana semua layanan Satu Persen berada di platform tersebut. Layanan seputar kesehatan mental dan pengembangan diri antara lain Webinar, Kelas Online, Mentoring, Konseling, Tes Online Gratis, dan Basic Mental Health Training. Dengan lebih dari 25 topik Webinar dan Kelas Online, lebih dari 40 orang mentor dan psikolog, lebih dari 270 ribu pengguna aktif setiap bulan, serta lebih dari 1 juta pengikut di sosial media, menjadikan Satu Persen sebagai Life School terbesar di Indonesia.</p>
                        </div>
                        <div className='mb-5 text-justify' data-aos='fade-up' data-aos-duration='1100'>
                            <h2 className='font-semibold text-lg'>Berbagai masalah kesehatan mental dan pengembangan diri yang dapat kamu temukan solusinya di Layanan Amanah:</h2>
                            <ul className='list-disc ml-5 text-sm'>
                                <li>Cara mengatasi masalah kesehatan mental</li>
                                <li>Cara mengatasi overthinking dan kecemasan</li>
                                <li>Cara mengatasi gangguan depresi dan trauma</li>
                                <li>Cara mengatasi quarter life crisis</li>
                                <li>Cara mengenal tipe kepribadian diri</li>
                                <li>Cara mengembangkan kemampuan dan potensi diri</li>
                                <li>Cara meningkatkan percaya diri</li>
                                <li>Cara mencintai diri sendiri</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const KomitmenKami = () => {
    return (
        <>
            <div className='bg-gradient-to-r from-blue-600 to bg-purple-800 h-fit w-full text-slate-800 py-5'>
                <div className='lg:container mx-auto'>
                    <h2 className='text-4xl text-center text-white font-bold my-5'>Komitmen Kami</h2>

                    <div className='lg:grid lg:grid-cols-2'>
                        <img src={IconKomitmen} alt="" className='h-80 lg:h-[30rem] w-fit bg-cover' data-aos='fade-up' data-aos-duration="1000" />

                        {/* section1 */}
                        <div>
                            <div className="p-5 chat chat-start" data-aos="zoom-in-up" data-aos-duration="1000">
                                <div className="chat-bubble bg-white text-black">
                                    <p>“Menyenangkan, kayak nemuin salah satu ujung benang kusut yang ada di otakku. Seenggaknya aku tau langkah-langkah yang perlu aku lakukan dan ketulusan mentor terasa ketika aku menceritakan masalahku. Jadi ga ngerasa di-judge karena punya masalah dan butuh bantuan.”</p>
                                    <br />
                                    <p className='font-bold'>Anonym</p>
                                </div>
                            </div>

                            {/* section2 */}
                            <div className='p-5 flex items-center' data-aos="zoom-in-up" data-aos-duration='1000'>
                                <div className='bg-white rounded-xl w-fit p-4'>
                                    <ul className='list-disc mx-10 font-semibold text-slate-700 text-xl'>
                                        <li>Mengurangi pikiran usutmu</li>
                                        <li>Mendengarkan dan mengerti kamu</li>
                                        <li>Membimbing dengan cara nyaman denganmu</li>
                                        <li>Menjaga privasimu</li>
                                        <li>Menemani kamu yang merasa sendirian</li>
                                        <li>Memberimu motivasi untuk hidup lebih bahagia</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const TerimaKasih = () => {
    return (
        <>
            <div className='lg:container mx-auto py-10'>
                <div className='flex text-center justify-center py-5'>
                    <h2 className='text-4xl font-bold'>Terima Kasih Telah Mempercayai Kami</h2>
                </div>

                <div className='text-slate-700 lg:flex lg:gap-10 justify-center my-10'>
                    <div className='text-center flex flex-col justify-center items-center' data-aos='fade-up' data-aos-duration='1000'>
                        <img src={IconMedsos} alt="" className='w-36' />
                        <h2 className='font-bold text-4xl'>10k+</h2>
                        <p className='text-sm text-slate-500'>Pengikut di sosial media</p>
                    </div>

                    <div className='text-center flex flex-col justify-center items-center' data-aos='fade-up' data-aos-duration='1500'>
                        <img src={IconMediaPlayer} alt="" className='w-36' />
                        <h2 className='font-bold text-4xl'>50+</h2>
                        <p className='text-sm text-slate-500'>Konten edukatif di sosial media</p>
                    </div>

                    <div className='text-center flex flex-col justify-center items-center' data-aos='fade-up' data-aos-duration='2000'>
                        <img src={IconKonseling} alt="" className='w-36' />
                        <h2 className='font-bold text-4xl'>100+</h2>
                        <p className='text-sm text-slate-500'>Total sesi Konseling & Mentoring</p>
                    </div>

                    <div className='text-center flex flex-col justify-center items-center' data-aos='fade-up' data-aos-duration='2500'>
                        <img src={IconAsesmenPsikologi} alt="" className='w-36' />
                        <h2 className='font-bold text-4xl'>250+</h2>
                        <p className='text-sm text-slate-500'>Asesmen Psikolog yang Terlaksana</p>
                    </div>
                </div>
            </div>
        </>
    )
}

const KataMereka = () => {
    return (
        <>
            <div className='bg-white h-fit w-full text-slate-800 shadow-xl'>
                <div className='py-20 bg-gradient-to-r from-blue-600 to bg-purple-800'>
                    <div className='lg:container mx-auto'>
                        <div className='text-center mb-5'>
                            <h2 className='text-4xl text-white font-bold'>Apa Kata Mereka?</h2>
                        </div>

                        <div className='grid grid-cols-2 lg:grid-cols-4 gap-5 lg:gap-10 p-2 lg:p-5'>
                            {/* 1 */}
                            <div className='bg-white p-3 lg:p-5 border-4 border-slate-300 rounded-box text-sm shadow-lg shadow-slate-700' data-aos='fade-right' data-aos-duration='800' >
                                <div className='grid justify-center items-center gap-3 font-semibold mb-5'>
                                    <img src={Profile1} alt="" className='rounded-full w-40 mx-auto' />
                                    <p className='text-xl text-center'>Ipul</p>
                                </div>
                                <div className='text-justify'>
                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Molestiae illo ut, optio quam quaerat quisquam. Asperiores molestiae libero neque enim architecto, accusantium, harum laboriosam ab</p>
                                </div>
                            </div>
                            {/* 2 */}
                            <div className='bg-white p-3 lg:p-5 border-4 border-slate-300 rounded-box text-sm shadow-lg shadow-slate-700' data-aos='fade-right' data-aos-duration='800' >
                                <div className='grid justify-center items-center gap-3 font-semibold mb-5'>
                                    <img src={Profile2} alt="" className='rounded-full w-40 mx-auto' />
                                    <p className='text-xl text-center'>Dayat</p>
                                </div>
                                <div className='text-justify'>
                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Molestiae illo ut, optio quam quaerat quisquam. Asperiores molestiae libero neque enim architecto, accusantium, harum laboriosam ab</p>
                                </div>
                            </div>
                            {/* 3 */}
                            <div className='bg-white p-3 lg:p-5 border-4 border-slate-300 rounded-box text-sm shadow-lg shadow-slate-700' data-aos='fade-right' data-aos-duration='800' >
                                <div className='grid justify-center items-center gap-3 font-semibold mb-5'>
                                    <img src={Profile3} alt="" className='rounded-full w-40 mx-auto' />
                                    <p className='text-xl text-center'>Reni</p>
                                </div>
                                <div className='text-justify'>
                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Molestiae illo ut, optio quam quaerat quisquam. Asperiores molestiae libero neque enim architecto, accusantium, harum laboriosam ab</p>
                                </div>
                            </div>
                            {/* 4 */}
                            <div className='bg-white p-3 lg:p-5 border-4 border-slate-300 rounded-box text-sm shadow-lg shadow-slate-700' data-aos='fade-right' data-aos-duration='800' >
                                <div className='grid justify-center items-center gap-3 font-semibold mb-5'>
                                    <img src={Profile4} alt="" className='rounded-full w-40 mx-auto' />
                                    <p className='text-xl text-center'>Rahman</p>
                                </div>
                                <div className='text-justify'>
                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Molestiae illo ut, optio quam quaerat quisquam. Asperiores molestiae libero neque enim architecto, accusantium, harum laboriosam ab</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const SliderAnimasiLogo = () => {
    return (
        <>
            <div className='bg-slate-100 h-fit w-full text-slate-800'>
                <div className='lg:container mx-auto'>
                    <div className='mx-auto py-10'>
                        <div className='flex justify-center mb-10'>
                            <h2 className='text-2xl lg:text-4xl font-bold text-center'>Telah Diliput di Berbagai Media</h2>
                        </div>

                        <Marquee className='flex items-center'>
                            <img className='w-fit h-40 mx-5' src={SliderAnimasi1} alt="" />
                            <img className='w-fit h-20 mx-5' src={SliderAnimasi2} alt="" />
                            <img className='w-fit h-40 mx-5' src={SliderAnimasi3} alt="" />
                            <img className='w-fit h-40 mx-5' src={SliderAnimasi4} alt="" />
                            <img className='w-fit h-20 mx-5' src={SliderAnimasi5} alt="" />
                            <img className='w-60 h-fit mx-5' src={SliderAnimasi6} alt="" />
                            <img className='w-fit h-20 mx-5' src={SliderAnimasi7} alt="" />
                            <img className='w-fit h-20 mx-5' src={SliderAnimasi8} alt="" />
                        </Marquee>
                    </div>
                </div>
            </div>
        </>
    )
}

const Homepage = () => {
    useEffect(() => {
        Aos.init()
    }, [])

    return (
        <>
            <div className='bg-white w-full text-black'>
                {/* navbar */}
                <Navbar />

                {/* layout */}
                <CarouselSlider />
                <LayananKami />
                <KomitmenKami />
                <TerimaKasih />
                <KataMereka />
                <SliderAnimasiLogo />
                <BannerDaftarKonseling />

                {/* footer */}
                <Footer />
            </div>
        </>
    )
}

export default Homepage