import React from 'react'
import { Link } from 'react-router-dom'

const Footer = () => {
    return (
        <div className='bg-slate-50'>
            <div className='lg:container mx-auto'>
                <footer className="footer p-5 text-slate-700 text-base">
                    <aside className='w-80'>
                        <div className='mb-5'>
                            <h2 className='footer-title'>Amanah Bestari</h2>
                            <p className='text-sm'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quaerat consectetur quidem quibusdam dicta illum eius nostrum saepe nam ipsa iure.</p>
                        </div>
                        <div className='mb-5'>
                            <h2 className='footer-title'>Customer Service</h2>
                            <p className='text-sm'>+62-821-8732-1459</p>
                        </div>
                        <div className='mb-5'>
                            <h2 className='footer-title'>Email</h2>
                            <p className='text-sm'>amanahtes18@gmail.com</p>
                        </div>
                        <div className='mb-5'>
                            <h2 className='footer-title'>Alamat</h2>
                            <ul className='list-disc text-sm ml-4'>
                                <li>Makassar, Jl. Pongtiku 1 Lr. 1 No. 6 Makassar, 90214</li>
                            </ul>
                        </div>
                        <nav>
                            <h6 className="footer-title">Social</h6>
                            <div className="grid grid-flow-col gap-4">
                                {/* ganti dengan menggunakan logo dari react icon */}
                                <a href='/'><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className="fill-current"><path d="M19.615 3.184c-3.604-.246-11.631-.245-15.23 0-3.897.266-4.356 2.62-4.385 8.816.029 6.185.484 8.549 4.385 8.816 3.6.245 11.626.246 15.23 0 3.897-.266 4.356-2.62 4.385-8.816-.029-6.185-.484-8.549-4.385-8.816zm-10.615 12.816v-8l8 3.993-8 4.007z"></path></svg></a>
                                <a href='/'><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className="fill-current"><path d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z"></path></svg></a>
                            </div>
                        </nav>
                    </aside>
                    <nav>
                        <h6 className="footer-title">Layanan</h6>
                        <a href='https://www.instagram.com/bibitind/' className="link link-hover">Bibit</a>
                        <a href='https://screenesia.com/' className="link link-hover">Screenasia</a>
                        <a href='https://grahacendekia.com/' className="link link-hover">Ganesha Cendekia Indonesia</a>
                        <Link to='/konseling-karir/konsultasi-program' className="link link-hover">Konsultasi Program Profesi TNI dan Polri</Link>
                        <Link to='/konseling-karir/perencanaan-karir' className="link link-hover">Perencanaan Karir</Link>
                        <Link to='/' className="link link-hover">Pelatihan Renang</Link>
                        <Link to='/' className="link link-hover">Terapi Tinggi Badan</Link>
                        <Link to='/' className="link link-hover">Pelatihan Fisik</Link>
                        <Link to='/' className="link link-hover">Terapi Buta Warna</Link>
                        <Link to='/' className="link link-hover">Latihan Kepemimpinan</Link>
                        <Link to='/' className="link link-hover">Program Outbond</Link>
                    </nav>
                    <nav>
                        <h6 className="footer-title">Asesmen Psikologi</h6>
                        <Link to='/asesmen-psikologi' className="link link-hover">Potensi Bakat dan Minat</Link>
                        <Link to='/asesmen-psikologi' className="link link-hover">Potensi Kesejahteraan Psikologi Anak</Link>
                        <Link to='/asesmen-psikologi' className="link link-hover">Deteksi Gaya Belajar Kecerdasan Majemuk</Link>
                        <Link to='/asesmen-psikologi' className="link link-hover">Uji Kompetensi dan Kompetensi Sumberdaya Manusia</Link>
                        <Link to='/asesmen-psikologi' className="link link-hover">Pelatihan Membaca Hasil Asesmen</Link>
                    </nav>
                    <nav>
                        <div className='mb-5 flex flex-col'>
                            <h6 className="footer-title">Kerja Sama</h6>
                            <Link to='/track-record' className="link link-hover">Track Record</Link>
                            <Link to='/' className="link link-hover">Organisasi</Link>
                            <Link to='/layanan-mitra' className="link link-hover">Mitra</Link>
                            <Link to='https://www.instagram.com/bibitind/' className="link link-hover">Screenasia</Link>
                            <Link to='https://screenesia.com/' className="link link-hover">Ganesha Cendekia Indonesia</Link>
                            <Link to='https://grahacendekia.com/' className="link link-hover">Layanan Training</Link>
                        </div>
                        <div className='mb-5 flex flex-col'>
                            <h6 className="footer-title">Tentang Kami</h6>
                            <Link to='/about-us' className="link link-hover">CV Amanah Bestari Corporation</Link>
                            <Link to='/struktur-organisasi' className="link link-hover">Struktur Organisasi</Link>
                        </div>
                    </nav>
                </footer>
            </div>

            <div className='py-4 bg-yellow-300 text-slate-700 text-center font-semibold'>
                <p>©2024 Created By Programmer</p>
            </div>
        </div>
    )
}

export default Footer