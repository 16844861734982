import React, { useState } from 'react'
import Navbar from '../../components/Navbar'
import Footer from '../../components/Footer'
import { Radio } from 'antd'
import { useNavigate } from 'react-router-dom'

const DaftarProgram = () => {
    const [paket, setPaket] = useState();
    const navigate = useNavigate();

    const namaProgram = [
        {
            id: 1,
            nm_paket: 'Samapta A: Pelatihan Renang',
            price: '125k/orang',
            deskripsi: 'Paket asesmen yang kami tawarkan untuk jumlah peserta diatas 200 orang'
        },
        {
            id: 2,
            nm_paket: 'Samapta B: Latihan Fisik Intensif',
            price: '150k/orang',
            deskripsi: 'Paket asesmen ini diperuntukkan untuk jumlah peserta diatas 100 orang'
        },
        {
            id: 3,
            nm_paket: 'Samapta C: Terapi Mata Minus',
            price: '250k/orang',
            deskripsi: 'Paket asesmen untuk perorangan'
        },
    ]
    const onChangePaket = (e) => {
        console.log(`radio checked:${e.target.value}`);
        setPaket(e.target.value);
    }

    const handleButtonLanjut = () => {
        navigate(`/layanan/form-daftar`, { state: { data: { paket } } });
    }

    return (
        <div>
            <Navbar />

            <div className='bg-white text-black py-10'>
                <div className='lg:container mx-auto p-5'>
                    {/* Asesmen */}
                    <div>
                        <div>
                            <h2 className='text-2xl font-bold'>Lihat daftar paket program</h2>
                        </div>
                        <div className='my-2'>
                            <p>Lihat daftar paket</p>
                            <p className='text-sm'>Lihat sesuai kebutuhanmu</p>
                        </div>
                        <div>
                            <Radio.Group onChange={onChangePaket} buttonStyle='outline' defaultValue="A" className='grid grid-cols-2 gap-5'>
                                {namaProgram.map((e) => {
                                    return (
                                        <Radio.Button key={e.id} value={e.nm_paket} className='h-fit grid'>
                                            <div className='p-5'>
                                                <div className='flex flex-col'>
                                                    <div className='w-full'>
                                                        <div className='flex justify-center items-center text-xl font-semibold'>
                                                            <p>{e.nm_paket}</p>
                                                        </div>
                                                        <hr className='border my-2 border-slate-300' />
                                                        <div className='flex text-center'>
                                                            <p>{e.deskripsi}</p>
                                                        </div>
                                                    </div>

                                                    <div className='flex justify-center items-center'>
                                                        <div className='text-center'>
                                                            <p className='text-xl font-semibold'>{e.price}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Radio.Button>
                                    )
                                })}
                            </Radio.Group>

                            <div className='flex justify-end my-5'>
                                <button onClick={handleButtonLanjut} className='bg-blue-500 py-2 px-4 font-semibold text-md text-white rounded-md transition duration-300 hover:bg-blue-700'>Lanjut</button>
                                {/* <a href="/layanan/daftar-mentor/form/" className='bg-blue-500 p-2 font-semibold text-md text-white rounded-md'>Lanjut</a> */}
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <Footer />
        </div>
    )
}

export default DaftarProgram