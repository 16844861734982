import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'

import idCard1 from '../img/id-card-1.png'
import idCard2 from '../img/id-card-2.png'
import cvAmanah from '../img/cv-amanah.png'

const IdCard = () => {
    return (
        <>
            <div className='lg:flex grid gap-4 justify-center'>
                <img src={idCard1} alt="" className='rounded-xl h-[40rem]' />
                <img src={idCard2} alt="" className='rounded-xl h-[40rem]' />
            </div>
        </>
    )
}

const CvAmanah = () => {
    return (
        <>
            <div className='bg-green-600 p-3 text-justify text-white my-5 text-xl grid gap-3'>
                <h2 className='font-bold'>CV Amanah Bestari Corp.</h2>
                <p>
                    Sebagai lembaga jasa pelayanan asesmen
                    psikologis sejak 2010 melayani berbagai instansi pemerintahan dan swasta
                    dalam penyelenggaraan asesmen psikologis serta bimbingan dan konseling
                    yang terus bergerak melakukan pengembangan serta inovasi dibidang
                    asesmen psikologi yang lebih efektif, efisien, valid dan reliable berbasis digital.</p>
                <img src={cvAmanah} alt="" />
            </div>
        </>
    )
}

const VisiMisi = () => {
    return (
        <>
            <div className='grid gap-2 text-xl text-white p-3 bg-blue-600'>
                <h2 className='text-2xl font-bold'>Visi Layanan Amanah</h2>
                <p>Memberikan Pelayanan Asesmen yang memiliki nilai kegunaan bagi
                    pengembangan sumber daya manusia dalam organisasi sehingga
                    mampu melahirkan data-data personal yang valid, akurat, reliable
                    secara efektif dan efisien.</p>
            </div>
        </>
    )
}

const AboutUs = () => {
    return (
        <div>
            <Navbar />

            <div className='bg-gradient-to-r from-blue-600 to bg-purple-800 w-full h-40 flex justify-center items-center'>
                <h1 className='text-6xl font-bold text-white'>About Us</h1>
            </div>
            <div className='bg-white h-fit w-full text-slate-800 py-10'>
                <div className='lg:container p-5 mx-auto'>
                    <IdCard />
                    <CvAmanah />
                    <VisiMisi />
                </div>
            </div>
            
            <Footer />
        </div>
    )
}

export default AboutUs