import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'

// import from image
import GambarLayananAsesmen from '../img/test-asesmen.jpg'
import IconAsesmen from '../img/icon6.png'
import { Link } from 'react-router-dom'

const InformasiLayanan = () => {
    return (
        <>
            <div id='apaitumentoring' className='bg-gradient-to-r from-blue-600 to bg-purple-800 p-5 lg:py-20 text-white'>
                <div className='lg:container mx-auto flex flex-col lg:grid lg:grid-cols-2 gap-5'>
                    <img src={IconAsesmen} alt="" className='h-fit w-5/6 rounded-3xl mx-auto' />
                    <div className='flex flex-col gap-5 text-center lg:text-left'>
                        <div>
                            <h2 className='text-6xl font-bold mb-5'>Layanan Asesmen Psikologi</h2>
                            <p>Mengidentifikasi adanya masalah psikologis yang membutuhkan perlakuan tertentu oleh tenaga ahli dibidang konseling maupun psikologi diantaranya pemberian psikoterapi, konseling, dan layanan psikologi lebih lanjut, termasuk diantaranya seleksi, penempatan dan pengambilan keputusan mengenai kelayakan individu</p>
                        </div>
                        <div className='bg-white shadow rounded-box text-black flex flex-col h-fit p-5 font-semibold'>
                            <p>Masalah yang sering ditangani termasuk:</p>
                            <hr className='border my-2 border-slate-400' />
                            <ul className='list-disc ml-4'>
                                <li>Asesmen Potensi Minat dan Bakat</li>
                                <li>Asesmen Potensi dan Kesejahteraan Psikologi Anak</li>
                                <li>Asesmen Deteksi Gaya Belajar Kecerdasan Majemuk</li>
                                <li>Asesmen Uji Kompetensi dan Sumber Daya Manusia</li>
                                <li>Pelatihan Membaca Hasil Asesmen</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const ProdukLayananAsesmen = () => {
    return (
        <>
            <div className='lg:container mx-auto pt-10 mb-10'>
                <h2 className='text-4xl text-center font-bold mb-5'>Produk Layanan Asesmen</h2>
                <hr className='border-2 border-blue-300 mb-5' />

                <div className='p-2'>
                    <img src={GambarLayananAsesmen} alt="" className='h-fit lg:h-[40rem] w-full lg:mx-auto border-2 rounded-box' />
                </div>

                <div className='px-5'>
                    {/* 1 */}
                    <div className="collapse collapse-arrow bg-white shadow my-3">
                        <input type="radio" name="my-accordion-2" defaultChecked />
                        <div className="collapse-title text-xl font-medium text-blue-700">
                            Asesmen Bakat Minat dan Potensi
                        </div>
                        <div className="collapse-content">
                            <p>Asesmen potensi bakat, minat dan emosi ( Untuk SMA/MA) diperuntukkan untuk pemilihan bidang studi atau penjurusan</p>
                        </div>
                    </div>

                    {/* 2 */}
                    <div className="collapse collapse-arrow bg-white shadow my-3">
                        <input type="radio" name="my-accordion-2" defaultChecked />
                        <div className="collapse-title text-xl font-medium text-blue-700">
                            Asesmen Potensi dan Kesejahteraan Psikososial Anak
                        </div>
                        <div className="collapse-content">
                            <p>Asesmen Potensi dan Kesejahteraan Psikososial Anak (Psychosocial well-being)(Untuk SD/MI dan SMP/MTs) diperuntukkan untuk deteksi dini potensi dan psikososial anak.</p>
                        </div>
                    </div>

                    {/* 3 */}
                    <div className="collapse collapse-arrow bg-white shadow my-3">
                        <input type="radio" name="my-accordion-2" defaultChecked />
                        <div className="collapse-title text-xl font-medium text-blue-700">
                            Asesmen deteksi gaya belajar kecerdasan majemuk
                        </div>
                        <div className="collapse-content">
                            <p>Asesmen deteksi gaya belajar kecerdasan majemuk (multiple intelligence)(Untuk SMK) diperuntukkan untuk pemetaan potensi keahlian pada jurusan yang sudah dipilih.</p>
                        </div>
                    </div>

                    {/* 4 */}
                    <div className="collapse collapse-arrow bg-white shadow my-3">
                        <input type="radio" name="my-accordion-2" defaultChecked />
                        <div className="collapse-title text-xl font-medium text-blue-700">
                            Asesmen Uji Kompetensi dan Sumber Daya Manusia
                        </div>
                        <div className="collapse-content">
                            <p>Asesmen uji potensi & kompetensi sumber daya manusia (Untuk Guru dan Karyawan) diperuntukkan untuk pengembangan guru dan karyawan</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const AlurPelaksanaan = () => {
    return (
        <>
            <div className='bg-slate-100 text-black text-center pb-10'>
                <div className='lg:container mx-auto'>
                    <h2 className='text-4xl font-bold text-center mb-5'>Alur Pelaksanaan</h2>
                    <hr className='border-2 border-blue-300 my-5' />
                    <ul className="steps steps-vertical text-xl font-semibold p-5 flex flex-col gap-2">
                        <li className="step step-info">
                            <p className='bg-blue-700 px-4 py-3 text-white rounded-box'>Handphone minimal ram 2 gb dan kuota minimal 1 gb dengan kondisi baterai 100%</p>
                        </li>
                        <li className="step step-info">
                            <p className='bg-blue-600 px-4 py-3 text-white rounded-box'>Link dikirimkan via grup WA dan dibuka dengan browser chrome</p>
                        </li>
                        <li className="step step-info">
                            <p className='bg-blue-500 px-4 py-3 text-white rounded-box'>Pengawas menjelaskan peraturan dan tata cara pengerjaan tes</p>
                        </li>
                        <li className="step step-info">
                            <p className='bg-blue-700 px-4 py-3 text-white rounded-box'>Peserta melakukan pengerjaan tes dengan durasi 90 menit</p>
                        </li>
                        <li className="step step-info lue">
                            <p className='bg-blue-600 px-4 py-3 text-white rounded-box'>Pengolahan data asesmen dan pembuatan laporan hasil asesmen selama 1 hari setelah pengetesan</p>
                        </li>
                        <li className="step step-info">
                            <p className='bg-blue-500 px-4 py-3 text-white rounded-box'>Data yang selesai diolah akan diproses oleh psikolog menjadi laporan hasil asesmen psikologis</p>
                        </li>
                        <li className="step step-info">
                            <p className='bg-blue-700 px-4 py-3 text-white rounded-box'>Laporan dikirim ke pihak sekolah baik secara digital maupun secara konvensional</p>
                        </li>
                        <li className="step step-info">
                            <p className='bg-blue-600 px-4 py-3 text-white rounded-box'>Sekolah yang menerima laporan lengkap dan mencairkan invoice pembayaran</p>
                        </li>
                        <li className="step step-info">
                            <p className='bg-blue-500 px-4 py-3 text-white rounded-box'>Proses asesmen selesai</p>
                        </li>
                    </ul>
                </div>
            </div>
        </>
    )
}

const PaketAsesmen = () => {
    const paketAsesmen = [
        {
            id: 1,
            nama_paket: 'Kolektif',
            harga: 'Rp 125.000/orang',
            keterangan: 'Paket asesmen yang kami tawarkan untuk jumlah peserta diatas 200 orang',
        },
        {
            id: 2,
            nama_paket: 'Median',
            harga: 'Rp 150.000/orang',
            keterangan: 'Paket asesmen ini diperuntukkan untuk jumlah peserta diatas 100 orang',
        },
        {
            id: 3,
            nama_paket: 'Individu',
            harga: 'Rp 250.000/orang',
            keterangan: 'Paket asesmen untuk perorangan',
        },
        {
            id: 4,
            nama_paket: 'Uji Kompetensi',
            harga: 'Rp 300.000/orang',
            keterangan: 'Paket asesmen ini dapat dipesan khusus sesuai kebutuhan dalam organisasi/intansi/institusi',
        },
    ]
    return (
        <>
            <div id='paket' className='lg:container mx-auto pb-10'>
                <h2 className='text-4xl font-bold text-center mb-5'>Paket Asesmen</h2>
                <hr className='border-2 border-blue-300 mb-5' />
                <div className='grid lg:grid-cols-2 gap-5 p-5'>
                    {paketAsesmen.map((e) => {
                        return (
                            <div className='bg-white p-5 rounded-box font-semibold shadow-xl transition duration-150 hover:shadow'>
                                <div className='flex gap-1'>
                                    <div className='w-full text-xl'>
                                        <p>Paket</p>
                                        <p className='text-blue-500'>{e.nama_paket}</p>
                                    </div>
                                    <div className='w-full flex justify-end'>
                                        <p className='text-xl text-red-400'>{e.harga}</p>
                                    </div>
                                </div>
                                <hr className='border border-slate-300 my-2' />

                                <div className=''>
                                    <p>{e.keterangan}</p>
                                </div>
                            </div>
                        )
                    })}
                </div>
                <div className='flex justify-center'>
                    <Link to="/layanan/daftar-asesmen" className='my-10 p-2 w-80 text-2xl text-center font-semibold bg-blue-500 text-white rounded-md transition duration-300 hover:bg-blue-600'>Daftar Sekarang</Link>
                </div>
            </div>s
        </>
    )
}

const AsesmenPsikologi = () => {
    return (
        <div>
            <Navbar />

            <div className='bg-slate-100 w-full text-slate-800'>
                <InformasiLayanan />
                <ProdukLayananAsesmen />
                <AlurPelaksanaan />
                <PaketAsesmen />
            </div>

            <Footer />
        </div>
    )
}

export default AsesmenPsikologi