import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'

// import image
import sman5Makassar2023 from '../img/pelaksanaan-sman5-makassar.png'
import sman4Bantaeng2023 from '../img/pelaksanaan-sman4-bantaeng.png'
import smk5MakassarSidrap2022 from '../img/smkn5-makassarsidrap-2022.png'
import smp2Maros2021 from '../img/smp2-unggulan-maros.png'

const TrackRecord = () => {
    return (
        <div>
            <Navbar />

            <div className='bg-white w-full text-slate-800'>
                <div className='lg:container mx-auto py-10 flex flex-col gap-5'>
                    <div className='text-center'>
                        <h2 className='text-4xl font-bold'>Track record layanan amanah</h2>
                    </div>

                    <div>
                        <img src={sman5Makassar2023} alt="" />
                        <img src={sman4Bantaeng2023} alt="" />
                        <img src={smk5MakassarSidrap2022} alt="" />
                        <img src={smp2Maros2021} alt="" />
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    )
}

export default TrackRecord