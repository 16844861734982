import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import BannerDaftarKonseling from '../components/Services/BannerDaftarKonseling'

const Mitra = () => {
    const urlMitra = [
        {
            id: 1,
            nama_mitra: 'Bibit',
            deskripsi: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Recusandae fugit mollitia perspiciatis unde, distinctio doloribus cupiditate, necessitatibus nihil blanditiis, itaque facere!',
            urlLanjut: 'https://www.instagram.com/bibitind/'
        },
        {
            id: 2,
            nama_mitra: 'Screenasia',
            deskripsi: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Recusandae fugit mollitia perspiciatis unde, distinctio doloribus cupiditate, necessitatibus nihil blanditiis, itaque facere!',
            urlLanjut: 'https://www.instagram.com/bibitind/'
        },
        {
            id: 3,
            nama_mitra: 'Ganesha Cendekia Indonesia',
            deskripsi: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Recusandae fugit mollitia perspiciatis unde, distinctio doloribus cupiditate, necessitatibus nihil blanditiis, itaque facere!',
            urlLanjut: 'https://www.instagram.com/bibitind/'
        },
    ]
    return (
        <>
            <div className='grid lg:grid-cols-3 gap-5 mt-5'>
                {urlMitra.map((e) => {
                    return (
                        <div className='p-3 shadow-xl rounded-box'>
                            <div className='flex mb-5'>
                                <p>Logo</p>
                                <h2 className='ml-5 font-semibold text-blue-500'>{e.nama_mitra}</h2>
                            </div>

                            <p>{e.deskripsi}</p>

                            <a href={e.urlLanjut} className='flex justify-center bg-indigo-500 text-white rounded mt-5 p-2 text-xl active:bg-indigo-800 hover:bg-indigo-700 transition duration-300'>Klik untuk selanjutnya</a>
                        </div>
                    )
                })}
            </div>
        </>
    )
}

const LayananMitra = () => {
    return (
        <div>
            <Navbar />

            <div className='bg-white h-fit w-full text-slate-800'>
                <div className='bg-gradient-to-r from-blue-600 to bg-purple-800 w-full h-40 flex justify-center items-center'>
                    <h1 className='text-6xl font-bold text-white'>Mitra Kami</h1>
                </div>

                <div className='lg:container mx-auto py-10 p-5'>
                    <div className='text-justify grid gap-3'>
                        <h2 className='font-bold text-2xl'>Mitra</h2>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Doloremque corporis iusto sapiente amet rerum labore vel natus sequi deserunt blanditiis, dolor minima quibusdam consequuntur inventore vero rem non praesentium ea! A laboriosam iure harum at impedit, eaque cupiditate nobis similique quis ducimus ut culpa enim et voluptatum iste sequi eligendi sed recusandae dolores ullam. Repudiandae dicta ut numquam sequi error quas neque assumenda omnis, dolore velit saepe exercitationem beatae, a consectetur quia ullam soluta mollitia porro quam veritatis veniam aliquid incidunt? Officiis, suscipit in fugiat consequatur, minima maxime magnam deleniti debitis autem quisquam, qui provident! Laborum at vel voluptas debitis.</p>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Rem assumenda consequatur, eaque cupiditate autem placeat molestias, maiores, rerum ullam possimus modi error. Aliquid ratione hic quisquam autem deserunt ipsam eum?</p>
                    </div>

                    <div className='bg-gradient-to-r from-blue-600 to bg-purple-800 shadow gap-2 grid p-5 rounded-box text-white text-justify font-semibold my-5'>
                        <h2 className='text-3xl'>Kata Mereka</h2>
                        <p className='font-normal'>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Odio, sequi excepturi! Nihil, voluptatum? Expedita atque non dolore similique consequatur impedit, quidem quaerat obcaecati sit, error sequi facilis voluptates saepe aspernatur.</p>
                    </div>

                    <Mitra />
                </div>

                <BannerDaftarKonseling />
            </div>

            <Footer />
        </div>
    )
}

export default LayananMitra