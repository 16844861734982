import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import { BiCheck } from 'react-icons/bi'

import alurKonsul1 from '../img/alur-konsultasi1.png'
import alurKonsul2 from '../img/alur-konsultasi2.png'
import alurKonsul3 from '../img/alur-konsultasi3.png'
import BannerDaftar from '../components/Services/BannerDaftarKonseling'
import GambarKonseling from '../img/konseling.jpg'
import { Link } from 'react-router-dom'

const KonsultasikanProfesi = () => {
    return (
        <>
            <div className='bg-gradient-to-r from-blue-600 to bg-purple-800 w-full py-10 px-5 text-white flex flex-col justify-center items-center'>
                <h1 className='text-6xl font-bold text-center'>Konsultasi Program Profesi TNI & Polri</h1>
                <p className='mt-10 font-semibold text-center'>Punya Masalah Kecemasan? Overthinking? Sulit Mengelola Emosi & Belum Mengenal Jati Diri? Konsultasiin aja bersama Satu Persen!</p>

                <div className='flex justify-center'>
                    <Link to="/layanan/daftar-mentor" className='mt-10 py-5 w-80 text-center font-semibold bg-white text-black rounded-full transition duration-300 hover:bg-blue-600 hover:text-white'>Daftar Sekarang</Link>
                </div>
            </div>
        </>
    )
}

const KonsultasiInformasi = () => {
    return (
        <>
            <div id='apaitumentoring' className='bg-cyan-500 py-5 text-white'>
                <div className='lg:container mx-auto p-5 grid gap-5 justify-between items-center lg:flex'>
                    <div className='flex flex-col'>
                        <p className='text-6xl font-bold mb-5 text-center lg:text-left'>Konsultasi sama mentor tuh ngapain aja sih?</p>
                        <ul className='list-disc p-5'>
                            <li>Kamu bisa cerita sama mentor terlatih.</li>
                            <li>Bareng-bareng cari solusi dari masalah yang sedang dihadapi.</li>
                            <li>Mencari solusi lewat worksheet yang dibuatkan sesuai dengan masalahmu.</li>
                            <li>Lebih mengenal diri sendiri melalui berbagai psikotes.</li>
                        </ul>
                    </div>

                    <div className='bg-white shadow rounded-box text-black flex flex-col lg:w-1/2 p-10 font-semibold'>
                        <p>Masalah yang sering ditangani termasuk:</p>
                        <hr className='border my-2 border-slate-400' />
                        <ul className='list-disc ml-4'>
                            <li>Lorem, ipsum dolor.</li>
                            <li>Lorem, ipsum dolor.</li>
                            <li>Lorem, ipsum dolor.</li>
                            <li>Lorem, ipsum dolor.</li>
                            <li>Lorem, ipsum dolor.</li>
                            <li>Lorem, ipsum dolor.</li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}

const InformasiProgram = () => {
    return (
        <>
            <div className='lg:container mx-auto p-5'>
                <div className='text-justify grid gap-5'>
                    <img src={GambarKonseling} alt='' className='h-fit lg:h-[25rem] w-full lg:w-8/12 lg:mx-auto border-2 rounded-box' />
                    <h2 className='text-2xl font-bold'>Konsultasi Program Profesi TNI & Polri</h2>
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Doloremque corporis iusto sapiente amet rerum labore vel natus sequi deserunt blanditiis, dolor minima quibusdam consequuntur inventore vero rem non praesentium ea! A laboriosam iure harum at impedit, eaque cupiditate nobis similique quis ducimus ut culpa enim et voluptatum iste sequi eligendi sed recusandae dolores ullam. Repudiandae dicta ut numquam sequi error quas neque assumenda omnis, dolore velit saepe exercitationem beatae, a consectetur quia ullam soluta mollitia porro quam veritatis veniam aliquid incidunt? Officiis, suscipit in fugiat consequatur, minima maxime magnam deleniti debitis autem quisquam, qui provident! Laborum at vel voluptas debitis.</p>
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Rem assumenda consequatur, eaque cupiditate autem placeat molestias, maiores, rerum ullam possimus modi error. Aliquid ratione hic quisquam autem deserunt ipsam eum?</p>
                </div>
            </div>
        </>
    )
}

const PaketKonsultasi = () => {
    return (
        <>
            <div id='paket' className='lg:container mx-auto'>
                <h2 className='text-4xl font-bold text-center my-10'>Paket konsultasi</h2>
                <div className='grid lg:grid-cols-3 gap-5 p-5'>
                    {/* 1 */}
                    <div className='bg-white p-5 rounded-box font-semibold shadow-xl transition duration-150 hover:shadow'>
                        <div className='flex p-3'>
                            <div className='w-full text-xl'>
                                <p>Paket</p>
                                <p className='text-green-500'>Super</p>
                            </div>
                            <div className='w-full'>
                                <p className='text-red-400 line-through'>300k</p>
                                <p className='text-3xl text-green-400'>Rp. 145k</p>
                            </div>
                        </div>
                        <hr className='border border-slate-300' />

                        <div className='p-3'>
                            <p className='text-xl'>1 Sesi</p>
                            <p className='text-green-400'>50 menit <span className='text-black'>sesi konsultasi</span></p>
                        </div>
                        <hr className='border border-slate-300' />

                        <div className='p-3'>
                            <div className='flex items-center'>
                                <BiCheck className='w-5 h-5 text-green-400' />
                                <p className='ml-1'>Catatan konsultasi</p>
                            </div>
                            <div className='flex items-center'>
                                <BiCheck className='w-5 h-5 text-green-400' />
                                <p className='ml-1'>Worksheet</p>
                            </div>
                            <div className='flex items-center'>
                                <BiCheck className='w-5 h-5 text-green-400' />
                                <p className='ml-1'>Lembar hasil psikotest</p>
                            </div>
                            <div className='flex items-center'>
                                <BiCheck className='w-5 h-5 text-green-400' />
                                <p className='ml-1'>Test kesehatan mental</p>
                            </div>
                        </div>
                    </div>

                    {/* 2 */}
                    <div className='bg-white p-5 rounded-box font-semibold shadow-xl transition duration-150 hover:shadow'>
                        <div className='flex p-3'>
                            <div className='w-full text-xl'>
                                <p>Paket</p>
                                <p className='text-fuchsia-500'>SuperPlus</p>
                            </div>
                            <div className='w-full'>
                                <p className='text-red-400 line-through'>350k</p>
                                <p className='text-3xl text-fuchsia-400'>Rp. 160k</p>
                            </div>
                        </div>
                        <hr className='border border-slate-300' />

                        <div className='p-3'>
                            <p className='text-xl'>1 Sesi</p>
                            <p className='text-fuchsia-400'>75 menit <span className='text-black'>sesi konsultasi</span></p>
                        </div>
                        <hr className='border border-slate-300' />

                        <div className='p-3'>
                            <div className='flex items-center'>
                                <BiCheck className='w-5 h-5 text-green-400' />
                                <p className='ml-1'>Catatan konsultasi</p>
                            </div>
                            <div className='flex items-center'>
                                <BiCheck className='w-5 h-5 text-green-400' />
                                <p className='ml-1'>Worksheet</p>
                            </div>
                            <div className='flex items-center'>
                                <BiCheck className='w-5 h-5 text-green-400' />
                                <p className='ml-1'>Lembar hasil psikotest</p>
                            </div>
                            <div className='flex items-center'>
                                <BiCheck className='w-5 h-5 text-green-400' />
                                <p className='ml-1'>Test kesehatan mental</p>
                            </div>
                            <div className='flex items-center'>
                                <BiCheck className='w-5 h-5 text-green-400' />
                                <p className='ml-1'>Test kepribadian</p>
                            </div>
                            <div className='flex items-center'>
                                <BiCheck className='w-5 h-5 text-green-400' />
                                <p className='ml-1'>Test minat karir</p>
                            </div>
                            <div className='flex items-center'>
                                <BiCheck className='w-5 h-5 text-green-400' />
                                <p className='ml-1'>Test distress psikologis</p>
                            </div>
                        </div>
                    </div>

                    {/* 3 */}
                    <div className='bg-white p-5 rounded-box font-semibold shadow-xl transition duration-150 hover:shadow'>
                        <div className='flex p-3'>
                            <div className='w-full text-xl'>
                                <p>Paket</p>
                                <p className='text-orange-500'>Bundle</p>
                            </div>
                            <div className='w-full'>
                                <p className='text-red-400 line-through'>300k</p>
                                <p className='text-3xl text-orange-400'>Rp. 145k</p>
                            </div>
                        </div>
                        <hr className='border border-slate-300' />

                        <div className='p-3'>
                            <p className='text-xl'>3 Sesi</p>
                            <p className='text-orange-400'>60 menit <span className='text-black'>sesi konsultasi</span></p>
                        </div>
                        <hr className='border border-slate-300' />

                        <div className='p-3'>
                            <div className='flex items-center'>
                                <BiCheck className='w-5 h-5 text-green-400' />
                                <p className='ml-1'>Catatan konsultasi</p>
                            </div>
                            <div className='flex items-center'>
                                <BiCheck className='w-5 h-5 text-green-400' />
                                <p className='ml-1'>Worksheet</p>
                            </div>
                            <div className='flex items-center'>
                                <BiCheck className='w-5 h-5 text-green-400' />
                                <p className='ml-1'>Lembar hasil psikotest</p>
                            </div>
                            <div className='flex items-center'>
                                <BiCheck className='w-5 h-5 text-green-400' />
                                <p className='ml-1'>Test kesehatan mental umum</p>
                            </div>
                            <div className='flex items-center'>
                                <BiCheck className='w-5 h-5 text-green-400' />
                                <p className='ml-1'>Test kepribadian</p>
                            </div>
                            <div className='flex items-center'>
                                <BiCheck className='w-5 h-5 text-green-400' />
                                <p className='ml-1'>Test minat karir</p>
                            </div>
                            <div className='flex items-center'>
                                <BiCheck className='w-5 h-5 text-green-400' />
                                <p className='ml-1'>Personalized treatment</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='flex justify-center'>
                    <Link to="/layanan/daftar-mentor" className='my-10 p-2 w-80 text-center font-semibold bg-blue-500 text-white rounded-md transition duration-300 hover:bg-blue-600'>Daftar Sekarang</Link>
                </div>
            </div>
        </>
    )
}

const AlurKonsultasi = () => {
    return (
        <>
            <div className='bg-slate-100 text-black text-center'>
                <div className='lg:container mx-auto lg:py-5'>
                    <div className='bg-white p-5 shadow-xl lg:rounded-box'>
                        <h2 className='text-3xl font-semibold'>Alur Konsultasi</h2>

                        <div className='grid lg:grid-cols-3 gap-5'>
                            <div>
                                <img src={alurKonsul1} alt="" className='w-40 h-40 mx-auto' />
                                <h2 className='text-xl font-semibold mt-5'>1. Pesan Produk</h2>
                                <p>Pilih paket konsultasi dengan metode yang paling nyaman untukmu</p>
                            </div>
                            <div>
                                <img src={alurKonsul2} alt="" className='w-40 h-40 mx-auto' />
                                <h2 className='text-xl font-semibold mt-5'>2. Jadwalkan Sesi</h2>
                                <p>Pilih mentor dan jadwalmu sendiri melalui dashboard Satu Persen</p>
                            </div>
                            <div>
                                <img src={alurKonsul3} alt="" className='w-40 h-40 mx-auto' />
                                <h2 className='text-xl font-semibold mt-5'>3. Mulai Konsultasi</h2>
                                <p>Mulai sesimu di Ruang Konsultasi (chatroom Satu Persen)</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const KonsultasiProgram = () => {
    return (
        <div>
            <Navbar />

            <div className='bg-slate-100 w-full text-slate-800'>
                <KonsultasikanProfesi />
                <KonsultasiInformasi />
                <InformasiProgram />
                <PaketKonsultasi />
                <AlurKonsultasi />
            </div>
            
            <BannerDaftar />
            <Footer />
        </div>
    )
}

export default KonsultasiProgram