import React, { useEffect } from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import Aos from 'aos'

const LatihanKepemimpinan = () => {
    useEffect(() => {
        Aos.init()
    })

    return (
        <div className=''>
            <Navbar />

            <div className='bg-white text-black'>
                <div className='lg:container mx-auto p-5'>
                    <div className='flex items-center justify-center h-40 w-full border-2 bg-slate-400 rounded-box lg:h-80'>Image</div>
                    <div className='text-justify my-5 grid gap-5'>
                        <h2 className='font-bold text-xl'>Latihan Kepemimpinan</h2>
                        <p>Apakah Anda bercita-cita untuk menjadi bagian dari kehormatan dan pengabdian dalam institusi Polri atau TNI? Di Layanan Amanah, kami memahami bahwa kesamaptaan fisik adalah salah satu aspek krusial dalam perjalanan menuju impian Anda. Itulah mengapa kami dengan bangga mempersembahkan program pelatihan khusus kami, dirancang untuk membantu anda mencapai standar poin kelulusan ujian kesamaptaan yang diperlukan untuk jenjang program seleksi masuk Polri atau TNI.</p>
                        <div className='grid lg:grid-cols-2 gap-3'>
                            <div data-aos="fade-up" aos-duration="3000" className='flex items-center justify-center h-40 lg:h-60 w-full border-2 bg-slate-400 rounded-box'>Image</div>
                            <div data-aos="fade-up" aos-duration="3000" className='flex items-center justify-center h-40 lg:h-60 w-full border-2 bg-slate-400 rounded-box'>Image</div>
                        </div>
                        <div className='lg:grid lg:grid-cols-2 gap-5'>
                            <div>
                                <h2 className='font-bold text-xl'>Mengapa harus memilih program kesamaptaan Layanan Amanah?</h2>
                                <ul className='list-decimal p-5'>
                                    <li>
                                        <span className='font-bold'>Pendekatan Terpadu: </span>
                                        <p>Kami menyajikan program pelatihan yang komprehensif dan terstruktur secara sistematis untuk memastikan Anda siap menghadapi setiap tahap tes fisik.</p>
                                    </li>
                                    <li>
                                        <span className='font-bold'>Instruktur Berpengalaman: </span>
                                        <p>Dibimbing oleh instruktur yang berpengalaman dan berkualitas, Anda akan mendapatkan bimbingan langsung dan dukungan penuh dalam mencapai target standar poin kesamaptaan Anda..</p>
                                    </li>
                                    <li>
                                        <span className='font-bold'>Program Kustomisasi: </span>
                                        <p>Kami memahami bahwa setiap individu memiliki kebutuhan unik. Oleh karena itu, kami menawarkan program yang dapat disesuaikan dengan kebutuhan dan kapasitas fisik Anda.</p>
                                    </li>
                                    <li>
                                        <span className='font-bold'>Fasilitas Konsultasi: </span>
                                        <p>Dilengkapi dengan fasilitator yang siap membimbing anda agar siap menghadapi ujian kesamaptaan, anda akan merasakan pengalaman pelatihan yang optimal dan nyaman</p>
                                    </li>
                                </ul>
                            </div>
                            <div className='grid gap-3'>
                                <div data-aos="fade-up" aos-duration="3000" className='flex items-center justify-center h-40 w-full border-2 bg-slate-400 rounded-box lg:hidden'>Image</div>
                                <h2 className='font-bold text-xl'>Mengapa harus memilih program kesamaptaan Layanan Amanah?</h2>
                                <ul className='list-decimal p-5'>
                                    <li>
                                        <span className='font-bold'>Pendekatan Terpadu: </span>
                                        <p>Kami menyajikan program pelatihan yang komprehensif dan terstruktur secara sistematis untuk memastikan Anda siap menghadapi setiap tahap tes fisik.</p>
                                    </li>
                                    <li>
                                        <span className='font-bold'>Instruktur Berpengalaman: </span>
                                        <p>Dibimbing oleh instruktur yang berpengalaman dan berkualitas, Anda akan mendapatkan bimbingan langsung dan dukungan penuh dalam mencapai target standar poin kesamaptaan Anda..</p>
                                    </li>
                                    <li>
                                        <span className='font-bold'>Program Kustomisasi: </span>
                                        <p>Kami memahami bahwa setiap individu memiliki kebutuhan unik. Oleh karena itu, kami menawarkan program yang dapat disesuaikan dengan kebutuhan dan kapasitas fisik Anda.</p>
                                    </li>
                                    <li>
                                        <span className='font-bold'>Fasilitas Konsultasi: </span>
                                        <p>Dilengkapi dengan fasilitator yang siap membimbing anda agar siap menghadapi ujian kesamaptaan, anda akan merasakan pengalaman pelatihan yang optimal dan nyaman</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    )
}

export default LatihanKepemimpinan